<template>
  <docs-layout class="space-y-4">
    <h1 class="text-3xl font-bold">Pagination</h1>
    <asom-pagination total-items="100" items-per-page="10" v-model="currentPage" />
    <p>Current page: {{currentPage}}</p>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'

export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      currentPage: 1,
    }
  }
}
</script>

<style>

</style>